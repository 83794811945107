"use client";
import { useEffect } from "react";
import useStyleStore from "src/stores/useStyleStore";
import { color } from "src/styles";

const getRandomString = () => Math.random().toString(36).substring(7);
const GlobalWebBackgroundManager = () => {
	useEffect(() => {
		const id = getRandomString();
		useStyleStore.setState({
			backgroundColorListeners: {
				...useStyleStore.getState().backgroundColorListeners,
				[id]: (overrideBackgroundColor) => {
					const topColor = Array.from(overrideBackgroundColor.values()).pop();
					if (topColor) {
						document.body.style.backgroundColor = topColor;
					} else {
						document.body.style.backgroundColor = color.Background.Dark;
					}
				},
			},
		});
		return () => {
			const existingListeners = useStyleStore.getState().backgroundColorListeners;
			delete existingListeners[id];
			useStyleStore.setState({
				backgroundColorListeners: {
					...existingListeners,
				},
			});
		};
	}, []);

	return null;
};
export default GlobalWebBackgroundManager;
